import React, { Fragment, useContext, useEffect } from "react";
import RankContext from "../../context/rank/rankContext";
import VoteItem from "./VoteItem";
import Spinner from "../layout/Spinner";
import AuthContext from "../../context/auth/authContext";
import { userMatches, isArrayEmpty } from "./rank_resuse";
import PropTypes from "prop-types";

const VoteList = ({ mode, rank }) => {
  const rankContext = useContext(RankContext);
  const { votes, current_category, current_rank, getVotes, loading_votes } =
    rankContext;

  const authContext = useContext(AuthContext);

  //mode = 0 -> display, 1 -> edit, 2 -> add
  const show = 0,
    edit = 1,
    add = 2;

  useEffect(() => {
    if (mode !== add) {
      getVotes({ item: rank._id });
    }
    // console.log('VoteList.useEffect');
    // eslint-disable-next-line
  }, [current_category, current_rank]);

  const panelTag = (vote) => (
    <VoteItem
      mode={userMatches(vote.user, authContext) ? edit : show}
      voteRecord={vote}
      key={"car-vote-item-" + vote._id}
      defaultCategory={rank}
    />
  );

  const showVoteList = () => {
    let i = 0;
    return isArrayEmpty(votes)
      ? null
      : votes.map((vote) => {
          if (vote.item === rank._id) {
            let tag = panelTag(vote);
            if (i !== 0) {
              tag = (
                <div>
                  <hr />
                  {tag}
                </div>
              );
            }
            i++;
            return tag;
          } else {
            return null;
          }
        });
  };

  // if (current_rank === undefined || current_rank === null) return ' ';
  if (loading_votes) return <Spinner />;
  // const idKey = 'slide' + rank._id;
  const showType = "other";

  return (
    <Fragment>
      <div>{showVoteList(showType)}</div>
    </Fragment>
  );
};

VoteList.propTypes = {
  mode: PropTypes.number,
  rank: PropTypes.object,
};

export default VoteList;
