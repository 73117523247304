import {
  GET_RANKS,
  ADD_RANK,
  DELETE_RANK,
  UPDATE_RANK,
  GET_CATEGORYS,
  GET_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  GET_VOTES,
  ADD_VOTE,
  DELETE_VOTE,
  UPDATE_VOTE,
  SET_CURRENT_CATEGORY,
  CLEAR_CURRENT_CATEGORY,
  FILTER_CATEGORY,
  CLEAR_FILTER_CATEGORY,
  SET_CURRENT_RANK,
  CLEAR_CURRENT_RANK,
  FILTER_RANK,
  CLEAR_FILTER_RANK,
} from "../types_rank";
// import { isObject } from '../../utils/functions_dates';

const rankReducer = (state, action) => {
  switch (action.type) {
    case GET_RANKS:
      return {
        ...state,
        ranks: action?.payload ? action?.payload : [],
        loading_rank: false,
      };

    case ADD_RANK:
      return {
        ...state,
        ranks: [action.payload, ...state.ranks],
        loading_rank: false,
        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_RANK:
      return {
        ...state,
        ranks: state.ranks.map((rank) => {
          return rank._id === action.payload._id ? action.payload : rank;
        }),
        loading_rank: false,
        // as above state is being passed and state is immutable, need to make a copy
      };

    case DELETE_RANK:
      return {
        ...state,
        ranks: state.ranks.filter((rank) => rank._id !== action.payload),
        loading_rank: false,
      };

    case GET_CATEGORYS:
      return {
        ...state,
        categorys: action.payload,
        loading_category: false,
      };

    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading_category: false,
      };

    case ADD_CATEGORY:
      return {
        ...state,
        categorys: [action.payload, ...state.categorys],
        loading_category: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_CATEGORY:
      return {
        ...state,
        categorys: state.categorys.map((category) =>
          category._id === action.payload._id ? action.payload : category
        ),
        loading_category: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case DELETE_CATEGORY:
      return {
        ...state,
        categorys: state.categorys.filter(
          (category) => category._id !== action.payload
        ),
        loading_category: false,
      };

    case GET_VOTES:
      return {
        ...state,
        votes: action.payload,
        loading_votes: false,
      };

    case ADD_VOTE:
      return {
        ...state,
        votes: [action.payload, ...state.votes],
        loading_votes: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case UPDATE_VOTE:
      return {
        ...state,
        votes: state.votes.map((vote) =>
          vote._id === action.payload._id ? action.payload : vote
        ),
        loading_votes: false,

        // as above state is being passed and state is immutable, need to make a copy
      };

    case DELETE_VOTE:
      return {
        ...state,
        votes: state.votes.filter((vote) => vote._id !== action.payload),
        loading_votes: false,
      };

    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        current_category: action.payload,
      };

    case CLEAR_CURRENT_CATEGORY:
      return {
        ...state,
        current_category: null,
      };

    case FILTER_CATEGORY:
      // --------- input
      // state.categorys = [{status: 'not paid', code: 'sg.dbs.cc'}, {status: 'paid', code: 'sg.ocbc.cc'}]
      // action.payload (filter) = {status: 'not paid', code: 'sg.dbs.cc'}
      // --------- output
      // filtered [{status: 'not paid', code: 'sg.dbs.cc'}]

      // const removeEmptyObjectValues = (data) => {
      //   if (!isObject(data)) return {};
      //   let obj = {};
      //   Object.keys(data).forEach((field) => {
      //     if (data[field] !== null && data[field] !== '')
      //       Object.assign(obj, { [field]: data[field] });
      //   });
      //   return obj;
      // };

      // const removeEmptyArrayItems = (array) => array.filter((el) => el != null);

      // let bAnd = false;
      // let query = removeEmptyObjectValues(action.payload);
      // const query_check = state.categorys.map((category) => {
      //   let found = Object.keys(query).map(
      //     // loop through filter criteria
      //     (field) => query[field] === category[field]
      //   );
      //   console.log(found);
      //   if (bAnd) {
      //     return found.includes(false) ? null : category;
      //   } else {
      //     return found.includes(true) ? category : null;
      //   }
      // });
      // let filterset = removeEmptyArrayItems(query_check);

      // return {
      //   ...state,
      //   filtered:
      //     filterset !== undefined && filterset !== null
      //       ? filterset
      //       : state.categorys,
      // };

      return {
        ...state,
        filtered_category: action.payload,
      };

    case CLEAR_FILTER_CATEGORY:
      return {
        ...state,
        filtered_category: null,
      };

    case SET_CURRENT_RANK:
      return {
        ...state,
        current_rank: action.payload,
      };

    case CLEAR_CURRENT_RANK:
      return {
        ...state,
        current_rank: null,
      };

    case FILTER_RANK:
      // --------- input
      // state.ranks = [{status: 'not paid', code: 'sg.dbs.cc'}, {status: 'paid', code: 'sg.ocbc.cc'}]
      // action.payload (filter) = {status: 'not paid', code: 'sg.dbs.cc'}
      // --------- output
      // filtered [{status: 'not paid', code: 'sg.dbs.cc'}]

      // const removeEmptyObjectValues = (data) => {
      //   if (!isObject(data)) return {};
      //   let obj = {};
      //   Object.keys(data).forEach((field) => {
      //     if (data[field] !== null && data[field] !== '')
      //       Object.assign(obj, { [field]: data[field] });
      //   });
      //   return obj;
      // };

      // const removeEmptyArrayItems = (array) => array.filter((el) => el != null);

      // let bAnd = false;
      // let query = removeEmptyObjectValues(action.payload);
      // const query_check = state.ranks.map((rank) => {
      //   let found = Object.keys(query).map(
      //     // loop through filter criteria
      //     (field) => query[field] === rank[field]
      //   );
      //   console.log(found);
      //   if (bAnd) {
      //     return found.includes(false) ? null : rank;
      //   } else {
      //     return found.includes(true) ? rank : null;
      //   }
      // });
      // let filterset = removeEmptyArrayItems(query_check);

      // return {
      //   ...state,
      //   filtered:
      //     filterset !== undefined && filterset !== null
      //       ? filterset
      //       : state.ranks,
      // };

      return {
        ...state,
        filtered_rank: action.payload,
      };

    case CLEAR_FILTER_RANK:
      return {
        ...state,
        filtered_rank: null,
      };

    default:
      return state;
  }
};

export default rankReducer;
