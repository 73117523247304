import React from "react";

const DisplayImage = ({ rank, theMode, mode, onChange, loggedIn }) => {
  const showImage = (rank) => {
    let options = {};
    let rankImage = rank?.image || "blankImage.jpg";
    let rankName = rank?.name || "add";

    if (mode === theMode.edit) options.value = rankImage;
    return (
      <div
        // className="bg-success"
        className="text-center"
        style={{
          verticalAlign: "top",
          padding: "2px",
          margin: "2px",
        }}
      >
        {imageDisplay(rankImage, rankName)}
        {mode === theMode.show || !loggedIn ? "" : displayImageField(options)}
      </div>
    );
  };

  const imageDisplay = (imageFile, imageName) => {
    if (!imageFile) return <div>No image</div>;
    let src;
    if (imageFile.type === "image/jpeg") {
      src = URL.createObjectURL(imageFile);
    } else if (imageFile === "blankImage.jpg") {
      src = imageFile;
    } else {
      src = `https://rank-imaginators-images.s3-ap-southeast-1.amazonaws.com/${imageFile}`;
    }

    return (
      <img
        style={{ height: "100px" }}
        // style={{ height: "100px", maxHeight: "100px" }}
        className="image_thumb"
        src={src}
        alt={imageName}
      />
    );
  };

  const displayImageField = (options) => {
    return (
      <div className="mt-1">
        <input
          type="file"
          className="grid-input-acct"
          name="image"
          onChange={onChange}
        />
      </div>
    );
  };

  return showImage(rank);
};

export default DisplayImage;
