import React, { Fragment, useContext, useEffect } from "react";
import RankContext from "../../context/rank/rankContext";
import { isArrayEmpty } from "../../utils/functions_dates";

const CategorySelect = () => {
  const rankContext = useContext(RankContext);
  const {
    categorys,
    current_category,
    setCurrentCategory,
    clearCurrentCategory,
    getCategorys,
  } = rankContext;

  useEffect(() => {
    getCategorys();
    // eslint-disable-next-line
  }, [current_category]);

  const setCategory = (e) => {
    let cat_record = getCategoryRecord(e.target.value);
    // console.log(cat_record);
    if (e.target.value !== "Please Select." && cat_record !== null) {
      setCurrentCategory(cat_record);
    } else {
      clearCurrentCategory();
    }
  };

  const getCategoryRecord = (name) => {
    if (categorys !== undefined && categorys !== null && categorys !== "") {
      let obj = categorys.find((o) => o.name === name);
      return obj;
    }
  };

  // eslint-disable-next-line
  // const isObjectEmpty = (obj) => !(obj !== undefined && obj !== null);

  return (
    <Fragment>
      <span>
        <select
          name="select_category"
          value={
            current_category !== undefined && current_category !== null
              ? current_category.name
              : "Please Select."
          }
          onChange={(e) => {
            setCategory(e);
          }}
        >
          <option key="Please Select." value="Please Select.">
            Please select.
          </option>
          {!isArrayEmpty(categorys) ? (
            categorys.map((cat) => <option key={cat._id}>{cat.name}</option>)
          ) : (
            <option key="loading" value="loading">
              loading ...
            </option>
          )}
        </select>
      </span>
    </Fragment>
  );
};

export default CategorySelect;
