// CATEGORY CONSTS
export const GET_CATEGORYS = 'GET_CATEGORYS';
export const GET_CATEGORY = 'GET_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';

export const FILTER_CATEGORY = 'FILTER_CATEGORY';
export const CLEAR_FILTER_CATEGORY = 'CLEAR_FILTER_CATEGORY';

export const CATEGORY_ERROR = 'CATEGORY_ERROR';

// RANKS CONSTS
export const GET_RANKS = 'GET_RANKS';
export const ADD_RANK = 'ADD_RANK';
export const DELETE_RANK = 'DELETE_RANK';
export const UPDATE_RANK = 'UPDATE_RANK';

export const SET_CURRENT_RANK = 'SET_CURRENT_RANK';
export const CLEAR_CURRENT_RANK = 'CLEAR_CURRENT_RANK';

export const FILTER_RANK = 'FILTER_RANK';
export const CLEAR_FILTER_RANK = 'CLEAR_FILTER_RANK';

export const RANK_ERROR = 'RANK_ERROR';

// VOTE CONSTS
export const GET_VOTES = 'GET_VOTES';
export const ADD_VOTE = 'ADD_VOTE';
export const DELETE_VOTE = 'DELETE_VOTE';
export const UPDATE_VOTE = 'UPDATE_VOTE';

export const SET_CURRENT_VOTE = 'SET_CURRENT_VOTE';
export const CLEAR_CURRENT_VOTE = 'CLEAR_CURRENT_VOTE';

export const FILTER_VOTE = 'FILTER_VOTE';
export const CLEAR_FILTER_VOTE = 'CLEAR_FILTER_VOTE';

export const VOTE_ERROR = 'VOTE_ERROR';

// ALERT CONSTS
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// AUTH CONSTS
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_NOT_LOADED = 'USER_NOT_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
