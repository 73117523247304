import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import RankContext from "../../context/rank/rankContext";
import AuthContext from "../../context/auth/authContext";
import Spinner from "../layout/Spinner";

import StarRatings from "./StarRatings";

import { ActionButton } from "../../utils/functions_dates";
import {
  theMode,
  buttonPrefix,
  againButton,
  voteOnChange,
  imageOnChange,
  getUserID,
  showAgainButtons,
} from "./rank_resuse";
import AgainStarsComments from "./AgainStarsComments";

const VoteItem = ({ mode, voteRecord, onVoteChange, defaultCategory }) => {
  const rankContext = useContext(RankContext);
  const { loading_votes, addVote, updateVote, current_category } = rankContext;

  const authContext = useContext(AuthContext);

  const getItemId = (mode, theMode) => {
    if (theMode.show === mode) {
      return voteRecord.item;
    } else if (theMode.add === mode) {
      return defaultCategory._id;
    } else if (theMode.addParent === mode) {
      return defaultCategory._id;
    } else if (theMode.edit === mode) {
      return voteRecord.item;
    }
  };

  const default_vote_set = {
    user: getUserID(authContext),
    item: getItemId(mode, theMode),
    stars: "",
    votes: "",
    vote: "",
    comments: "",
  };
  const [vote, setVote] = useState(default_vote_set);

  useEffect(() => {
    if (
      mode === theMode.show ||
      mode === theMode.edit ||
      mode === theMode.addParent
    ) {
      setVote(voteRecord);
    } else {
      setVote(default_vote_set);
    }
    // eslint-disable-next-line
  }, [voteRecord]);

  const buttonStyle = "btn btn-outline-light btn-sm";

  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(
        buttonStyle,
        onSaveAdd,
        <i className="fa fa-save" />,
        {
          data: vote,
          action: "save",
        }
      );
    },
    btn_Add: function btn_Add() {
      return ActionButton(
        buttonStyle,
        onSaveAdd,
        <i className="fas fa-plus-circle" />,
        {
          data: vote,
          action: "add",
        }
      );
    },
    btn_Edit: function btn_Edit() {
      return ActionButton(buttonStyle, "", <i className="far fa-edit" />, vote);
    },
    btn_Cancel: function btn_Cancel() {
      return ActionButton(buttonStyle, "", "Cancel", "");
    },
    btn_Delete: function btn_Delete() {
      return ActionButton(buttonStyle, "", "Delete", {
        data: vote,
        action: "delete",
      });
    },
  };

  const onChange = (...args) => {
    let name = args[2] || args[0].target?.name;
    let chg;

    if (name === "image") {
      chg = imageOnChange(args[0]);
    } else if (name.indexOf("vote.") > -1) {
      chg = voteOnChange(name);
    } else if (name.includes("stars")) {
      chg = ["stars", args[0]];
    } else {
      chg = [name, args[0].target.value];
    }

    setVote({
      ...vote,
      [chg[0]]: chg[1],
    });
  };

  const onSaveAdd = (args) => {
    let vote = args.data;

    if (args.action === "add") {
      addVote(vote);
    } else {
      updateVote(vote);
    }
    clearForm();
  };

  const clearForm = () => {
    // setRank(default_rank_set);
    // clearCurrentRank();
  };

  const showButtons = (mode) =>
    mode === theMode.show || mode === theMode.addParent ? (
      ""
    ) : mode === theMode.add ? (
      <div>{fns["btn_Add"]()}</div>
    ) : (
      <div>{fns["btn_Save"]()}</div>
    );

  if (vote === undefined || vote === null) return " ";
  if (loading_votes && vote === undefined) return <Spinner />;

  const buttonPre =
    mode === theMode.add || mode === theMode.show
      ? buttonPrefix(defaultCategory, current_category)
      : buttonPrefix(vote, current_category);

  const voteKey = vote._id || "add";

  return (
    <Fragment>
      <div className="row" key={"card-body-r1-" + voteKey}>
        <div className="col" key={"card-body-r1c1-" + voteKey}>
          <div className="float-right">{showButtons(mode)}</div>
        </div>
      </div>
      <AgainStarsComments
        record={vote}
        theMode={theMode}
        mode={mode}
        buttonPre={buttonPre}
        onChange={onChange}
        onVoteChange={onVoteChange}
        keyId={"votes-comments-" + voteKey}
      />
    </Fragment>
  );
};

VoteItem.propTypes = {
  mode: PropTypes.number,
  voteRecord: PropTypes.object.isRequired,
  onVoteChange: PropTypes.func,
  defaultCategory: PropTypes.object,
};

export default VoteItem;
