import React, { Fragment, useContext, useEffect } from 'react';
import RankContext from '../../context/rank/rankContext';

const CategoryMap = () => {
  const rankContext = useContext(RankContext);
  const { categorys, current_category, setCurrentCategory } = rankContext;

  useEffect(() => {
    // eslint-disable-next-line
  }, [current_category]);

  // eslint-disable-next-line
  // const isObjectEmpty = (obj) => !(obj !== undefined && obj !== null);

  const isArrayEmpty = (obj) =>
    !(obj !== undefined && obj !== null && obj.length !== 0);

  const showCatMap = (cat) => (
    // <span key={'cat_map_span' + cat._id}>
    <button
      key={'cat_map_' + cat._id}
      className='btn btn-sm btn-primary'
      onClick={() => setCurrentCategory(cat)}
    >
      {cat.name + '  '}
    </button>
  );

  return (
    <Fragment>
      <div>
        {!isArrayEmpty(categorys)
          ? categorys.map((cat) => showCatMap(cat))
          : ' '}
      </div>
    </Fragment>
  );
};

export default CategoryMap;
