import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import setAuthToken from "../../utils/setAuthToken";
import PropTypes from "prop-types";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  USER_NOT_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Load User
  const loadUser = async () => {
    // if token exists, set token in config
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      // try getting user from auth
      const res = await axios.get("/api/auth");

      if (!res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: USER_NOT_LOADED,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Register User
  const register = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/users", formData, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      // load user and return user details
      loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: REGISTER_FAIL,
        payload: err.msg,
      });
    }
  };

  // Login User
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/auth", formData, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      // load user and return user details
      loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      let errMsg = getError(err);
      dispatch({
        type: LOGIN_FAIL,
        payload: errMsg.data.msg,
      });
    }
  };

  const getError = (error) => {
    let data;
    let status = 500;
    if (error.response) {
      // status code out of the range of 2xx
      data = error.response.data;
      status = error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      data = JSON.parse(error.request.response);
    } else {
      // Error on setting up the request
      data = error.message;
    }
    return { status, data };
  };

  // Logout User
  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  // Clear Errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        register,
        loadUser,
        login,
        logout,
        clearErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  children: PropTypes.object,
};

export default AuthState;
