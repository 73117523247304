import React, { Fragment } from "react";
// import authContext from '../../context/auth/authContext';

export const default_rank_set = {
  category: "",
  name: "",
  votes: "",
};

export const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isArray = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const theMode = { show: 0, edit: 1, add: 2, addParent: 3 };

export const showMode = (mode, theMode) =>
  Object.entries(theMode).map((arr) => (arr[1] === mode ? arr[0] : ""));

export const buttonPrefix = (rank, current_category) =>
  rank?.categorys !== undefined
    ? rank?.categorys[0].prefix
    : current_category?.prefix || "do";

export const cardClass = (rank) =>
  !rank.votes || rank.votes === 0
    ? "bg-light"
    : rank.votes > 0
    ? "bg-success"
    : "bg-danger";

export const showAgainButtons = (hasVotes, show, data, buttonPre, onChange) => {
  const showAgain = !show || (hasVotes && data?.votes > 0) || !data?.votes;
  const showAgainDont = !show || (hasVotes && data?.votes < 0) || !data?.votes;

  return show ? (
    <Fragment>
      {againButton(true, buttonPre, onChange, showAgain)}
      {againButton(false, buttonPre, onChange, showAgainDont)}
    </Fragment>
  ) : (
    <Fragment>
      {againButton(true, buttonPre, onChange, showAgain)}
      {againButton(false, buttonPre, onChange, showAgainDont)}
    </Fragment>
  );
};

export const againButton = (again, prefix, onChange, enabled) => {
  return again ? (
    <button
      className={
        "btn btn-sm btn-success text-white " + (enabled ? "active" : "disabled")
      }
      style={{ minWidth: "8rem" }}
      name={"vote.up"}
      onClick={onChange}
      value={true}
      disabled={!enabled}
      aria-disabled={!enabled}
      aria-pressed={enabled}
    >
      {prefix + " again"}
    </button>
  ) : (
    <button
      className={
        "btn btn-sm btn-danger text-white " + (enabled ? "active" : "disabled")
      }
      style={{ minWidth: "8rem" }}
      name={"vote.down"}
      onClick={onChange}
      value={false}
      disabled={!enabled}
      aria-disabled={!enabled}
      aria-pressed={enabled}
    >
      {"won't " + prefix + " again"}
    </button>
  );
};

export const voteOnChange = (name) => {
  let chg = name.indexOf(".up") > -1 ? 1 : -1;
  return ["votes", chg];
};

export const imageOnChange = (e) => {
  return [e.target.name, e.target.files[0]];
};

export const attrOnChange = (rank, e) => {
  let name = e.target.name;
  let names = name.split("."); // get attr index
  let key = names[1]; // get attributes[index]

  name = "attributes";
  let val = rank.attributes;
  val[key] = e.target.value;
  return [name, val];
};

export const isArrayEmpty = (obj) =>
  !(obj !== undefined && obj !== null && obj.length !== 0);

export const getUserID = (authContext) =>
  authContext.isAuthenticated && authContext.user ? authContext.user._id : "";

export const userMatches = (user, authContext) =>
  getUserID(authContext).toString() === user;

export const showButtonFromArray = (arr, fns) => arr.map((but) => fns[but]());

export const userCreatedRank = (record, current_rank, authContext) =>
  rankIsCurrent(current_rank, record._id) &&
  userMatches(record.user, authContext);

export const rankSelected = (current_rank) =>
  current_rank && current_rank !== null;

export const rankIsCurrent = (current_rank, id) =>
  rankSelected(current_rank) && current_rank._id === id;

// const getCategoryAttrs = (id) => findInObj(categorys, '_id', id).attributes;

// // -------------- GENERIC FUNCTIONS
// const fields = [
//   {
//     field: 'category',
//     title: 'Category',
//     type: 'string',
//   },
//   { field: 'name', title: 'Name', type: 'name' },
//   { field: 'attributes', title: 'Attributes', type: 'string' },
//   {
//     field: 'image',
//     title: 'Image',
//     type: 'image',
//   },
// ];

// // -------------- DISPLAY FUNCTIONS

// const getCategoryRecord = (name) => {
//   if (categorys !== undefined && categorys !== null && categorys !== '') {
//     let obj = categorys.find((o) => o.name === name);
//     return obj;
//   }
// };

// // edit when???

// const showSingleAttribute = (field_name, index, value) => {
//   let options = {};
//   if (mode === edit) options.value = value;
//   return (
//     <input
//       {...options}
//       key={field_name}
//       type='text'
//       className='grid-input-acct'
//       name={'attributes.' + field_name}
//       placeholder={field_name.toLowerCase()}
//       onChange={onChange}
//     />
//   );
// };
// const showCategory = (categorys) => (
//   <div>
//     {categorys !== undefined && categorys !== null && categorys.length > 0
//       ? categorys[0].name
//       : ' '}
//   </div>
// );
