import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

const Home = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <h1>Welcome to Rank item tracker</h1>
        <span>Please click a link below to start</span>
        <br />
      </div>
      <div>
        <br />
        <ul>
          <li>
            <Link to="/ranks">Ranks</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
