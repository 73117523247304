import React, { Fragment, useContext, useEffect } from "react";
import RankItem from "./RankItem";
import RankContext from "../../context/rank/rankContext";
import { theMode } from "./rank_resuse";
import Spinner from "../layout/Spinner";

let debug = 0;
const db = debug >= 1 ? true : false;

const RankList = () => {
  let dp = ".com.ranks.ranklist";

  const rankContext = useContext(RankContext);
  const {
    ranks,
    getRanksSet,
    loading_rank,
    current_category,
    filtered,
    // current_rank,
    // theMode,
  } = rankContext;

  const cat_selected = (obj) => obj?._id !== undefined;

  useEffect(() => {
    if (db) console.log(`${dp}:start`);
    // // if a filter set exists, use it instead of ranks else use ranks set
    // //   no need to assign to the displaySet as checks will be done in the jsx to see if records exist in either filtered or ranks
    if (cat_selected(current_category)) {
      getRanksSet({ category_id: current_category._id });
    } else {
      getRanksSet();
    }
    if (db) console.log(`${dp}:finish`);
    // eslint-disable-next-line
  }, [current_category]);

  // const show = 0,
  //   edit = 1,
  //   add= 2;

  const noRecordsCard = () => (
    <div className="col">
      <span>no records exist, please add an item</span>
    </div>
  );

  const showRanks = (ranks) =>
    ranks.map((rank) => (
      <RankItem key={rank._id} rankRecord={rank} mode={theMode.show} />
    ));

  const showRankContent = () => {
    if (filtered?.length > 0) return showRanks(filtered);
    if (ranks?.length > 0) return showRanks(ranks);
    return noRecordsCard();
  };
  // if (loading_rank) return <Spinner />;

  return (
    <Fragment>
      <div className="col">
        <RankItem key={"add_record"} rankRecord={{}} mode={theMode.add} />
      </div>
      {showRankContent()}
    </Fragment>
  );
};

export default RankList;
