import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Alerts from "./components/layout/Alerts";
import About from "./components/pages/About";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Home from "./components/pages/Home";

import AlertState from "./context/alert/AlertState";
import ItemState from "./context/item/ItemState";
import AuthState from "./context/auth/AuthState";

import setAuthToken from "./utils/setAuthToken";

import RanksBasic from "./components/pages/Ranks";
import RankState from "./context/rank/RankState";

import "./App.css";
import "./table.css";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <AuthState>
      <RankState>
        <ItemState>
          <AlertState>
            <Router>
              <Fragment>
                <Navbar title="Ranks" icon="bi bi-wallet white-text" />
                <div className="container">
                  <Alerts />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/ranks" component={RanksBasic} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/login" component={Login} />
                  </Switch>
                </div>
              </Fragment>
            </Router>
          </AlertState>
        </ItemState>
      </RankState>
    </AuthState>
  );
};

export default App;
