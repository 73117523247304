import React, { useEffect, useContext } from 'react';
import RanksTag from '../rank/Ranks';
import AuthContext from '../../context/auth/authContext';

const RanksBasic = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        <RanksTag />
      </div>
    </div>
  );
};

export default RanksBasic;
