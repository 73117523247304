import { DateTime } from "luxon";

// // used in the report filters
// // checks if the key exists in the record and the value (regex) matches
// export const getFieldValue = (record, key, value) => {
//   return record[key] && record[key].match(value);
// };

// // used in the report filters
// // checks an list (object) of values and matches against a list of keys / fields
// export const checkFieldValueFromList = (record, keys, values) => {
//   for (let value of values.values) {
//     const regex = new RegExp(`${value}`, "gi");
//     for (let key of keys) {
//       if (getFieldValue(record, key, regex)) {
//         return true;
//       }
//     }
//   }
//   return false;
// };

// @name    parsePassedDate
// @desc    convert the passed date from api call in yyyymmdd format and transform to date value

export const formatDate = (value) =>
  (value = value ? DateTime.fromISO(value).toFormat("dd/LL/yy") : "");
export const formatFloat = (value) => (value = value ? "$" + value : "");

export const cleanFilters = (filters) => {
  if (filters) {
    for (const property in filters)
      if (filters[property] === "" || filters[property] === null)
        delete filters[property];
  }
  return filters;
};

export const getRecords = (data, filtered, filterOn) => {
  if (filterOn) {
    return filtered?.length ? filtered : null;
  } else {
    return data !== null && data?.length ? data : null;
  }
};

export const getRecordCount = (data, filtered, filterOn) => {
  if (!data && !filtered) return "0";
  return (filterOn ? filtered.length + "/" : "") + data?.length?.toString();
};

export const formatDLDate = (date) => {
  let d = DateTime.fromISO(date);
  return d.isValid ? d.toFormat("dd-MMM-yy") : "";
};

export const fldNotExistNullOrEmptyStr = (fld) => {
  return fld === undefined || fld === null || fld === "";
};

export const fldUpdIfEmp = (fld, val) =>
  fldNotExistNullOrEmptyStr(fld) ? val : fld;

export const changeCase = (text, textCase) => {
  let newText;
  switch (textCase.toLowerCase()) {
    case "upper":
      return text.toUpperCase();
    case "lower":
      return text.toLowerCase();
    case "sentence":
      text = text.split(".");
      newText = text.map(
        (t) => t.substr(0, 1).toUpperCase() + t.substr(1, t.length)
      );
      return newText.join(".");
    case "title":
      text = text.split(" ");
      newText = text.map((t) =>
        t.substr(0, 1).toUpperCase() + t.substr(1, t.length)
          ? t.substr(1, t.length)
          : ""
      );
      return newText.join(" ");
    default:
      return text;
  }
};

export const getValOr0 = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const daysBetweenDates = (date1, date2) => {
  if (
    date1 === undefined ||
    date1 === null ||
    date2 === undefined ||
    date2 === null
  )
    return null;

  let startDate = DateTime.fromISO(date1);
  let endDate = DateTime.fromISO(date2);

  if (startDate.isValid && endDate.isValid) {
    if (startDate === endDate) return 0;
    return startDate < endDate
      ? DateTime.fromISO(startDate).diff(endDate, "days").days
      : DateTime.fromISO(endDate).diff(startDate, "days").days * -1;
  }

  return null;
};

export const getValidDate = (d) => {
  let newDate = DateTime.fromJSDate(d);
  if (!newDate.isValid) newDate = DateTime.fromISO(d);
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "dd-LLL-yyyy");
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "dd/LLL/yyyy");
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "yyyy-LLL-dd");
  return newDate;
};

export const returnISODate = (d) => {
  // let dateSeg = d.split('-');
  // let newDate = new Date(dateSeg[0], dateSeg[1], dateSeg[2]);
  // newDate += 5 * 24;

  let newDate = DateTime.fromISO(d);
  return newDate.toISO();
};

export const ShowValueOrName = (name, type, value) => {
  let v = formatValue(type, value);
  return v === null || v === "" ? name : v;
};

export const numStrToNumArr = (string) =>
  string.length === 0 ? [] : string.replace(/, +/g, ",").split(",").map(Number);

export const arrStrToNumArr = (arr) => arr.map((num) => parseInt(num));

export const today = () => DateTime.local();

export const confirmVal = (fld) =>
  fld !== undefined && fld !== null ? fld : null;

export const emptyArr = (arr) => !(arr && arr !== null && arr.length > 0);

export const emptyObj = (obj) => !obj || obj === null;

export const getNumber = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const isNumeric = (num) => !isNaN(num);

export const isDate = (d) => {
  if (d !== undefined && d !== null) {
    let m = DateTime.fromISO(d);
    return m.isValid;
  } else {
    return false;
  }
};

// @name    getLastDayOfMonth
// @desc    return last day of month for passed year and month
export const getLastDayOfMonth = (y, m) => {
  return new Date(y, m + 1, 0).getDate();
};

// @name    getNextMonthDate
// @desc    return date for month + 1 of same day
export const getNextMonthDate = (d) => {
  var next_date = new Date(d);
  return new Date(next_date.setMonth(next_date.getMonth() + 1));
};

export const objHasKey = (obj, key) =>
  !emptyObj(obj) && Object.hasOwnProperty.call(obj, key);

export const findInObj = (arr, key, value) => arr.find((o) => o[key] === value);

export const findIndexInObj = (arr, key, value) =>
  arr.findIndex((o) => o[key] === value);

export const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

export const isObjectEmpty = (obj) => !(obj !== undefined && obj !== null);

export const isArrayEmpty = (obj) =>
  !(obj !== undefined && obj !== null && obj.length !== 0);

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isArray = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const parsePassedDate = (d) => {
  let p = [
    [0, 4],
    [4, 6],
    [6, 8],
  ];
  let r = p.map((ps) => parseInt(d.toString().substring(ps[0], ps[1])));
  return new Date(r[0], r[1] - 1, r[2], 0, 0, 0);
};

export const numberFormat = (value, dec) =>
  value === null ? "" : parseFloat(value).toFixed(dec);

export const currencyFormat = (value, decs) =>
  value === null
    ? ""
    : new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        maximumFractionDigits: decs,
        minimumFractionDigits: decs,
      }).format(value);

export const dateFormat = (value) => {
  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU").format(new Date(value))
    : "";
};

export const dateTimeFormat = (value) => {
  let format = {
    dateStyle: "short",
    timeStyle: "short",
  };

  format = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU", format).format(new Date(value))
    : "";
};

export const formatValue = (type, value) => {
  if (value === undefined || value === null || value === "null") return "";
  if (type === undefined || type === null || type === "") return value;

  switch (type.toLowerCase()) {
    case "date":
      return dateFormat(value);
    case "datetime":
      return dateTimeFormat(value);
    case "number":
      return numberFormat(value, 0);
    case "currency":
      return currencyFormat(value);
    case "float":
      return currencyFormat(value);
    case "array":
      return value.join(",");
    case "string":
    default:
      return value;
  }
};
