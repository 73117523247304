import React, { Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import RankContext from "../../context/rank/rankContext";
import AuthContext from "../../context/auth/authContext";

import CategorySelect from "./CategorySelect";
import CategoryMap from "./CategoryMap";

import RankList from "./RankList";
import RankItem from "./RankItem";

import { isObject } from "../../utils/functions_dates";
import { theMode, getUserID } from "./rank_resuse";

let debug = 0;
const db = debug >= 1 ? true : false;

const Ranks = () => {
  let dp = ".com.ranks";

  const authContext = useContext(AuthContext);
  const rankContext = useContext(RankContext);
  const { current_category } = rankContext;

  const category_selected = () =>
    current_category !== undefined &&
    current_category !== null &&
    isObject(current_category);

  return (
    <Fragment>
      <div className="container">
        <div className="row mt-1">
          <div className="col h3">
            {category_selected()
              ? current_category.prefix + " again"
              : "Select Category"}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <CategorySelect />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <CategoryMap />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col">{category_selected()}</div>
        </div>
        <div className="row mt-1">
          <div className="col">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-3 g-4">
                <RankList />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </Fragment>
  );
};

export default Ranks;
