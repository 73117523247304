import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import RankContext from "../../context/rank/rankContext";
import AuthContext from "../../context/auth/authContext";
import VoteList from "./VoteList";
import VoteItem from "./VoteItem";
import { Link } from "react-router-dom";

// import { useDropzone } from 'react-dropzone';
import {
  theMode,
  buttonPrefix,
  voteOnChange,
  imageOnChange,
  attrOnChange,
  showButtonFromArray,
  userCreatedRank,
  getUserID,
  userMatches,
} from "./rank_resuse";
import { ActionButton } from "../../utils/functions_dates";
import Spinner from "../layout/Spinner";
import DisplayImage from "./DisplayImage";
import CardTemplate from "./CardTemplate";
import AgainStarsComments from "./AgainStarsComments";

let debug = 0;
let db = debug >= 1 ? true : false;
let dp = "com.rankItem";

const RankItem = ({ rankRecord, mode }) => {
  if (db) console.log(`${dp}:start`);

  const rankContext = useContext(RankContext);
  const {
    current_rank,
    setCurrentRank,
    clearCurrentRank,
    addRank,
    updateRank,
    deleteRank,
    current_category,
  } = rankContext;

  const authContext = useContext(AuthContext);

  const default_rank_set = {
    _id: "",
    user: "",
    category: current_category === null ? {} : current_category?._id,
    name: "",
    attributes: {},
    image: "",
    stars: "",
    votes: "",
    comments: "",
  };
  const [rank, setRank] = useState(default_rank_set);

  useEffect(() => {
    if (mode === theMode.add) {
      setRank(default_rank_set);
    } else if (mode === theMode.show) {
      setRank(rankRecord);
    } else if (mode === theMode.edit) {
      if (current_rank) {
        setRank(current_rank);
      } else {
        setRank(rankRecord);
      }
    }
    // eslint-disable-next-line
    // }, [current_category]);
  }, [rankContext, current_category]);

  const loggedIn = getUserID(authContext) !== "";
  const catSelected = !!current_category;
  const rankSelected = !!current_rank;

  mode =
    loggedIn && userCreatedRank(rank, current_rank, authContext)
      ? theMode.edit
      : mode;
  // if (rankRecord === undefined || rankRecord === null) return <Spinner />;

  const show = theMode.show === mode;
  const add = theMode.add === mode;

  const _id = show ? rank?._id : "add";
  const key = "rank-" + _id;

  // const hasVotes = rank?.votes || rank?.votes === 0;
  const buttonPre = buttonPrefix(rank, current_category);

  const buttonStyle = "btn btn-sm btn-primary";
  // fa fa-check
  const fns = {
    btn_Save: function btn_Save() {
      return ActionButton(
        buttonStyle,
        onSaveAdd,
        "Save",
        {
          data: rank,
          action: "save",
        },
        "save-" + _id
      );
    },
    btn_Add: function btn_Add() {
      return ActionButton(
        buttonStyle,
        onSaveAdd,
        "Add",
        {
          data: rank,
          action: "add",
        },
        "add-" + _id
      );
    },
    btn_Edit: function btn_Edit() {
      return ActionButton(
        buttonStyle,
        setCurrentRank,
        "Edit",
        rank,
        "edit-" + _id
      );
    },
    btn_Cancel: function btn_Cancel() {
      return ActionButton(
        buttonStyle,
        clearCurrentRank,
        "Cancel",
        "",
        "cancel-" + _id
      );
    },
    btn_Delete: function btn_Delete() {
      return ActionButton(
        buttonStyle,
        onDelete,
        "Delete",
        {
          data: rank,
          action: "delete",
        },
        "delete-" + _id
      );
    },
  };

  const showAddOrLogin = () => {
    if (theMode.add === mode && (!loggedIn || !catSelected))
      return showLoginMsg();
  };

  const showLoginMsg = () => {
    return (
      <div className="text-center">
        <Link to="/login" className="text-primary">
          Login
        </Link>{" "}
        and select category to add item
      </div>
    );
  };

  const showNameLink = (rank) => {
    // if (mode === theMode.add) return <p>New item to rate!</p>;
    let name = rank?.name || "add-rank";
    let editingRank = current_rank?._id === rank?._id;

    return (
      <div>
        {editingRank || mode === theMode.add ? (
          showNameInput(rank)
        ) : rankSelected ? (
          <Fragment>
            <button
              className="buttonAsLink"
              value={name}
              onClick={() => clearCurrentRank()}
            >
              {name}
            </button>
          </Fragment>
        ) : (
          <button
            className="buttonAsLink"
            value={name}
            onClick={() => setCurrentRank(rank)}
          >
            {name}
          </button>
        )}
      </div>
    );
  };

  const showNameInput = (rank) => {
    const nameInput = () => {
      let options = { name: "name" };
      options.className = "grid-input-acct";
      options.placeholder = "name";
      options.type = "text";
      options.placeholder = rank?.name || "enter a name";
      options.value = rank?.name;
      options.name = "name";

      return <input {...options} onChange={onChange} />;
    };

    return (
      <div>
        <Fragment>{nameInput()}</Fragment>
      </div>
    );
  };

  const showVoteList = (rank) => {
    return mode === theMode.edit ? (
      ""
    ) : mode !== theMode.add ? (
      <VoteList mode={mode} rank={rank} />
    ) : (
      <VoteItem
        mode={theMode.addParent}
        voteRecord={rank}
        onVoteChange={onChange}
      />
    );
  };

  const showVotes = () => {
    if (theMode.show !== mode) return " ";
    return ShowAccordianSection(accordianShowComments);
  };

  const accordianShowComments = [
    {
      title: "show votes",
      prefix: "showComments" + _id,
      content: showVoteList,
      params: rank,
      type: "Function",
    },
  ];

  const ShowAccordianSection = (accordianSections) => {
    return (
      <div className="accordian m-0 p-0" id="accordionParent">
        <div className="accordian-item m-0 p-0" id="accordionItem">
          {accordianSections.map((section, index) => {
            let k = section.prefix + "-" + index;
            return (
              <Fragment key={"frag-" + k}>
                <div className="row my-2 p-0" key={`head-r-${k}`}>
                  <div className="col m-0 p-0" key={`head-c-${k}`}>
                    <div
                      className="accordian-header m-0 p-0"
                      key={`accord-header-${k}`}
                      id={`collapseHeader-${k}`}
                    >
                      <button
                        className="accordion-button buttonAsLink"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${k}`}
                        aria-expanded="true"
                        aria-controls={`collapse-${k}`}
                        key={`btn-header-${k}`}
                      >
                        {section.title}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  id={`collapse-${k}`}
                  className="row accordion-collapse collapse"
                  aria-labelledby={`collapseHeader-${k}`}
                  data-bs-parent="#accordionParent"
                  key={`card-accordian-${k}`}
                >
                  <div className="col" key={`card-body-${k}`}>
                    {section.type === "Function"
                      ? section.content(section.params)
                      : section.content}
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const showAttributes = (rank) => {
    let attrs =
      current_category?.attributes || Object.keys(rank?.attributes) || [];

    let i = 0;
    return attrs.map((field) => {
      let tag =
        mode === theMode.show
          ? getSepValueIfExists(rank?.attributes[field], "|", i)
          : showControl(field, rank);
      i++;
      return tag;
    });
  };

  const showControl = (fieldName, rank) => {
    let options = {};
    if (mode === theMode.edit && rank.attributes[fieldName] !== undefined)
      options.value = rank?.attributes[fieldName];

    return (
      <input
        {...options}
        key={fieldName}
        type="text"
        className="grid-input-acct"
        name={"attributes." + fieldName}
        placeholder={fieldName.toLowerCase()}
        onChange={onChange}
      />
    );
  };

  const getSepValueIfExists = (value, sep, i) =>
    value === undefined ? "" : (i !== 0 ? ` ${sep} ` : "") + value;

  const showAddEditCancelButtons = (rank) => {
    return mode === theMode.show ? (
      loggedIn && userMatches(rank.user, authContext) ? (
        showButtonFromArray(["btn_Edit"], fns)
      ) : (
        ""
      )
    ) : (
      <div>
        <div>
          {mode === theMode.edit
            ? showButtonFromArray(["btn_Save", "btn_Cancel"], fns)
            : showButtonFromArray(["btn_Add", "btn_Cancel"], fns)}
        </div>
      </div>
    );
  };

  // ************ client event functions

  const onChange = (...args) => {
    let name = args[2] || args[0].target?.name;
    let chg;

    if (name === "image") {
      chg = imageOnChange(args[0]);
    } else if (name.indexOf("vote.") > -1) {
      chg = voteOnChange(name);
    } else if (name.indexOf("attributes") > -1) {
      chg = attrOnChange(rank, args[0]);
    } else if (name.includes("stars")) {
      chg = ["stars", args[0]];
    } else {
      chg = [name, args[0].target.value];
    }

    setRank({
      ...rank,
      [chg[0]]: chg[1],
    });
  };

  const onDelete = (obj) => deleteRank(obj?.data?._id);

  const onSaveAdd = (args) => {
    let rank = args.data;

    if (args.action === "add") {
      addRank(rank);
    } else {
      updateRank(rank);
    }
    clearForm();
  };

  const clearForm = () => {
    setRank(default_rank_set);
    clearCurrentRank();
  };

  // ************ end client event functions

  // ************ core section functions

  const imageSection = () => {
    return (
      <Fragment>
        <DisplayImage
          rank={rank}
          theMode={theMode}
          mode={mode}
          loggedIn={loggedIn}
          onChange={onChange}
        />
      </Fragment>
    );
  };

  const titleSection = () => {
    return showNameLink(rank);
  };

  const contentSection = () => {
    return (
      <Fragment>
        <div className="row" key={`card-content-r2-${key}`}>
          <div className="col text-center" key={`card-content-r2c1-${key}`}>
            {showAttributes(rank)}
          </div>
        </div>
        <AgainStarsComments
          record={rank}
          theMode={theMode}
          mode={mode}
          buttonPre={buttonPre}
          onChange={onChange}
          keyId={"votes-comments" + key}
          showVotes={true}
        />
        <div className="row" key={`card-content-r4-${key}`}>
          <div className="col" key={`card-content-r4c1-${key}`}>
            {showVotes()}
          </div>
        </div>
        <div className="row mt-1" key={`card-content-r5-${key}`}>
          <div className="col m-1 text-center" key={`card-content-r5c1-${key}`}>
            {showAddEditCancelButtons(rank)}
          </div>
        </div>
      </Fragment>
    );
  };

  const addNotLoggedIn = () => {
    const imageSection = () => (
      <DisplayImage
        rank={{}}
        mode={mode}
        theMode={theMode}
        loggedIn={loggedIn}
      />
    );
    const titleSection = () => <div>Add item to rank</div>;
    const contentSection = () => <div>{showAddOrLogin()}</div>;
    return (
      <CardTemplate
        imageSection={imageSection}
        titleSection={titleSection}
        contentSection={contentSection}
        keyId={key}
      />
    );
  };

  if (add && (!loggedIn || !catSelected)) return addNotLoggedIn();

  return (
    <CardTemplate
      imageSection={imageSection}
      titleSection={titleSection}
      contentSection={contentSection}
      keyId={key}
    />
  );
};

RankItem.propTypes = {
  mode: PropTypes.number,
  rankRecord: PropTypes.object,
};

export default RankItem;
